<div
  *ngIf="hasCampaignTracking; else gatedContent"
  class="statscol"
  [ngbTooltip]="
    targetGroupAmount >= 0
      ? ('two_values_targetgroup_list' | transloco)
      : ('data-yesterday' | transloco)
  "
  tooltipClass="status-info-tooltip"
  placement="top"
  (mouseover)="showTooltip = true"
  (mouseout)="showTooltip = false"
>
  <div class="title schrift_dunkelgrau">
    <ng-container *ngIf="!hasCampaignAssigned; else regularStats">
      <span>-</span>
    </ng-container>

    <ng-container *ngIf="targetGroupAmount >= 0">
      <span
        *ngIf="unit !== 'Click Rate'; else clickRateTargetGroup"
        class="schrift_mowe_grau ms-2"
        >({{ targetGroupAmount }})</span
      >
    </ng-container>
  </div>

  <div class="subline">
    <span transloco="{{ unit }}" style="vertical-align: text-top"></span>
    <mt-svg
      *ngIf="showTooltip"
      src="assets/images/svgs/info.svg"
      class="mt-hellgrau mini-svg marleft5"
      style="display: inline-block"
    ></mt-svg>
  </div>
</div>

<ng-template #regularStats>
  <span *ngIf="unit !== 'Click Rate'; else clickRate">{{ amount }}</span>
</ng-template>

<ng-template #clickRate>
  <span> {{ amount * 100 | number: '1.2-2' }} %</span>
</ng-template>

<ng-template #clickRateTargetGroup>
  <span class="schrift_mowe_grau ms-2">
    ({{ targetGroupAmount * 100 | number: '1.2-2' }} %)</span
  >
</ng-template>

<ng-template #gatedContent>
  <div class="statscol">
    <div class="title gated">
      <span>?</span>
    </div>
    <div class="subline schrift_dunkelgrau">
      <span>{{ unit }}</span>
    </div>
  </div></ng-template
>
