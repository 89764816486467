import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
  selector: 'statscol',
  templateUrl: './stats-col.component.html',
  styleUrls: ['./stats-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgbTooltip, TranslocoModule, MtSvgComponent, DecimalPipe]
})
export class StatsColComponent {
  /**
   * campaignTracking status of user
   * @defaultValue true
   */
  @Input() hasCampaignTracking = true;

  /**
   * Statistics amount to display
   * @defaultValue 0
   */
  @Input() amount = 0;

  /**
   * Statistics type
   * @defaultValue ''
   */
  @Input() unit = '';

  /**
   * **Used only for target groups**
   *
   * Determines if a '-' or number should be displayed
   * based on if there is a campaign assigned or not
   *
   * @defaultValue true
   */
  @Input() hasCampaignAssigned = true;

  /**
   * **Used only for target groups**
   *
   * Statistics for a target group
   *
   * Used to compare the overall statistics of the target group
   * with the current assigned event/campaign
   */
  @Input() targetGroupAmount!: number;

  /**
   * Determines if the tooltip icon should be displayed
   */
  showTooltip = false;
}
